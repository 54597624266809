;(function( window, document, $, undefined ) {
  'use strict';

  var home = (function() {

    var $private = {};
    var $public = {};
    var screen_width = $(window).width();

    $public.mainSlider = function() {
      var mainSlider = $('.dd-bxslider').bxSlider({
        controls: false,
        pager: false,
        auto: true,
        onSliderLoad : function() {
          $('.dd-section-slider').css('visibility' , 'visible');

          if($(".dd-bxslider li").length < 4) {
            $('.dd-container-controls').hide();
          }
        },
        onSlideAfter: function() {
          mainSlider.stopAuto();
          mainSlider.startAuto();
        }
      });

      $('.dd-arrow-left').on('click', function(){
        mainSlider.goToPrevSlide();
      });

      $('.dd-arrow-right').on('click', function(){
        mainSlider.goToNextSlide();
      });
    }

    $public.boxSearch = function() {
      $('.dd-icon-search').click(function (){
        $('.dd-section-search .dd-container-form').stop().slideToggle('fast');
      });
    }

    $public.logoSlider = function() {

      // Desktop version
      function sliderLogoDesktop() {
        var mainSlider = $('.dd-logo-list').bxSlider({
          pager: false,
          controls: false,
          minSlides: 5,
          maxSlides: 5,
          moveSlides: 5,
          slideMargin: 26,
          slideWidth: 190,
          responsive: true,
          infiniteLoop: false,
          onSliderLoad : function() {
            $('.dd-section-plans').css('visibility' , 'visible');

            if($(".dd-logo-list li").length <= 5) {
              $('.dd-section-plans .fa').addClass('dd-hide');
            }
          }
        });

        $('.dd-arrow-left-logo').on('click', function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-arrow-right-logo').on('click', function(){
          mainSlider.goToNextSlide();
        });
      }
      // end Desktop version

      // Tablet version
      function sliderLogoTablet() {
        var mainSlider = $('.dd-logo-list').bxSlider({
          pager: false,
          controls: false,
          minSlides: 3,
          maxSlides: 3,
          moveSlides: 1,
          slideMargin: 10,
          slideWidth: 210,
          responsive: true,
          infiniteLoop: false,
          onSliderLoad : function() {
            $('.dd-section-plans').css('visibility' , 'visible');

            if($(".dd-logo-list li").length <= 3) {
              $('.dd-section-plans .fa').addClass('dd-hide');
            }
          }
        });

        $('.dd-arrow-left-logo').on('click', function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-arrow-right-logo').on('click', function(){
          mainSlider.goToNextSlide();
        });
      }
      // end Tablet version

      // Mobile version
      function sliderLogoMobile() {
        var mainSlider = $('.dd-logo-list').bxSlider({
          pager: false,
          controls: false,
          minSlides: 2,
          maxSlides: 2,
          moveSlides: 1,
          slideMargin: 10,
          slideWidth: 186,
          responsive: true,
          infiniteLoop: false,
          onSliderLoad : function() {
            $('.dd-section-plans').css('visibility' , 'visible');

            if($(".dd-logo-list li").length <= 2) {
              $('.dd-section-plans .fa').addClass('dd-hide');
            }
          }
        });

        $('.dd-arrow-left-logo').on('click', function(){
          mainSlider.goToPrevSlide();
        });

        $('.dd-arrow-right-logo').on('click', function(){
          mainSlider.goToNextSlide();
        });
      }
      // end Mobile version

      if (screen_width <= 480) {
        sliderLogoMobile();
      }else if (screen_width >= 481 && screen_width <= 767){
        sliderLogoTablet();
      }else if(screen_width >= 768){
        sliderLogoDesktop();
      }

    }

    return $public;
  })();

  // Global
  window.home = home;
  home.mainSlider();
  home.boxSearch();
  home.logoSlider();

})( window, document, jQuery );

$(window).load(function() {
});
